// src/components/common/layout.js
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const Layout = ({ children }) => {
  // Determine the region and canonical URL based on the build environment.
  const contactRegion = process.env.GATSBY_CONTACT_REGION || "us";
  const canonicalUrl =
    contactRegion === "ca"
      ? "https://laundrytogo.ca/"
      : "https://laundrytogo.com/";

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'LaundryToGo' },
              { name: 'keywords', content: 'site, web' },
            ]}
          >
            <html lang="en" />
            {/* Self-canonical tag */}
            <link rel="canonical" href={canonicalUrl} />
            {/* hreflang tags */}
            <link rel="alternate" hreflang="en-ca" href="https://laundrytogo.ca/" />
            <link rel="alternate" hreflang="en-us" href="https://laundrytogo.com/" />
          </Helmet>
          <div>{children}</div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
