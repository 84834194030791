// src/components/footer.js
import React from "react";
import { Link } from "gatsby";
import logo from "../../images/logo.png";
import logoDark from "../../images/logo-top.svg";
import "../../assets/sass/main.scss";

// Import the global contact info
import contactInfo from "../config/contactInfo";

const Footer = (props) => (
  <>
    <footer
      id="desktop-footer"
      className={`wrapper footer style1-alt ${props.bg || ""}`}
    >
      <div className="row">
        <div className="col-md-2 col-md-offset-10 text-right logo-container-mobile">
          <img
            src={props.bg === "white" ? logoDark : logo}
            className="logo-web"
            alt="LaundryToGo"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <h3 className="footer-col-title">Reference</h3>
          <ul className="list">
            <li>
              <a href="/howitworks">How it works</a>
            </li>
            <li>
              <a href="/offer">Gift Card</a>
            </li>
            <li>
              <a href="/help">FAQ</a>
            </li>
            <li>
              <a href="/covidInfo">Covid'19 Resources</a>
            </li>
            <li>
              <a href="/partner">Partner</a>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-sm-12">
          <h3 className="footer-col-title">Address</h3>
          <p style={{ whiteSpace: "pre-line", lineHeight: "1.6" }}>
            {contactInfo.address.join("\n")}
            <br />
            {contactInfo.phone}
          </p>
        </div>
        <div className="col-md-4 col-sm-12"></div>
      </div>
      <div className="row">
        <div className="col-md-7 col-sm-12 social">
          <a
            href="https://www.facebook.com/laundrytogo.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="col-md-1 col-sm-4">
              <i className="fa fa-facebook-official"></i>
            </span>
          </a>
          <a
            href="https://www.instagram.com/laundrytogo.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="col-md-1 col-sm-4">
              <i className="fa fa-instagram"></i>
            </span>
          </a>
          <a
            href="https://www.twitter.com/laundrytogo_ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="col-md-1 col-sm-4">
              <i className="fa fa-twitter"></i>
            </span>
          </a>
          <a href={`mailto:${contactInfo.email}`}>
            <span className="col-md-1 col-sm-4">
              <i className="fa fa-envelope"></i>
            </span>
          </a>
        </div>
        <div className="col-md-5 col-sm-12 social text-right">
          <span className="col-md-6 text-right">
            <i className="fa fa-globe"></i>&nbsp;&nbsp;EN
          </span>
          <span className="col-md-6 col-sm-4 text-right">
            <i className="fa fa-map-marker"></i>&nbsp;&nbsp;{contactInfo.footerLocation}
          </span>
        </div>
      </div>
      <div className="row finalFooterRow">
        <br />
        <br />
        <div className="col-md-10 col-sm-12 filterCols">
          <span>&copy; 2020 LaundryToGo Inc</span>
        </div>
        <div className="col-md-2 col-sm-12 filterCols">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Privacy</span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Terms</span>
        </div>
      </div>
    </footer>
    <footer
      id="mobile-footer"
      className={`wrapper footer style1-alt ${props.bg || ""}`}
    >
      <div className="row">
        <div className="col-sm-6 half-col-mobile">
          <img
            src={props.bg === "white" ? logoDark : logo}
            className="logo-web"
            alt="Laundry To Go"
          />
        </div>
        <div className="col-sm-6 half-col-mobile">
          <h3 className="footer-col-title">Address</h3>
          <p style={{ whiteSpace: "pre-line", lineHeight: "1.6" }}>
            {contactInfo.address.join("\n")}
            <br />
            {contactInfo.phone}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 half-col-mobile">
          <h3 className="footer-col-title">Reference</h3>
          <span className="col-md-6 text-right block-social-link">
            <a href="/howitworks">How it works</a>
          </span>
          <span className="col-md-6 text-right block-social-link">
            <a href="/offer">Gift Card</a>
          </span>
          <span className="col-md-6 text-right block-social-link">
            <a href="/help">FAQ</a>
          </span>
          <span className="col-md-6 block-social-link">
            <a href="/covidInfo">Covid'19 Resources</a>
          </span>
          <span className="col-md-6 block-social-link">
            <a href="/partner">Partner</a>
          </span>
        </div>
        <div className="col-sm-6 half-col-mobile">
          <h3 className="footer-col-title">Social</h3>
          <a
            href="https://www.facebook.com/laundrytogo.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="col-md-6 block-social-link">Facebook</span>
          </a>
          <a
            href="https://www.instagram.com/laundrytogo.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="col-md-6 block-social-link">Instagram</span>
          </a>
          <a
            href="https://www.twitter.com/laundrytogo_ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="col-md-6 block-social-link">Twitter</span>
          </a>
          <a href={`mailto:${contactInfo.email}`}>
            <span className="col-md-6 block-social-link">E-Mail</span>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 half-col-mobile">
          <br />
          <br />
          <span className="col-md-6 block-social-link">
            {contactInfo.footerLocation}
          </span>
        </div>
      </div>
      <div className="row finalFooterRow">
        <br />
        <br />
        <div className="col-md-6 filterCols half-col-mobile-first">
          <span>&copy; 2020 LaundryToGo Inc</span>
        </div>
        <div className="col-md-6 filterCols half-col-mobile-second">
          <span>Privacy</span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Terms</span>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
