// src/config/contactInfo.js
const contactRegions = {
  us: {
    phone: "(212) 470-2303",
    email: "contactus@laundrytogo.com",
    address: ["167 Madison Avenue,", "New York, NY, 10016"],
    footerLocation: "New York City",
  },
  ca: {
    phone: "(647) 722-4585",
    email: "contactus@laundrytogo.ca",
    address: ["25 Telegram Mews,", "Toronto, ON, M5V 3Z1"],
    footerLocation: "Greater Toronto Area",
  },
};

const region = process.env.GATSBY_CONTACT_REGION || "us";
export default contactRegions[region];
